<template>
  <div class="virusTips">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">报毒提醒</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <p>
        本平台有主要收益为广告赞助，且保证APP安全无毒，因平台主要展示内容为色情属于特殊行业，某些杀毒软件会误报毒提醒，如遇此类提醒请忽略继续使用。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.virusTips {
  height: 100%;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    font-size: 12px;
    color: #333;
    line-height: 24px;
    p {
      margin-bottom: 30px;
    }
  }
}
</style>
